<template>
  <c-flex
    flex-dir="column"
    w="100%"
  >
    <Breadcrumbs
      :items="[
        {
          label: 'Manajemen Notifikasi',
          href: null,
          isCurrent: true,
        },
      ]"
    />

    <hr
      v-chakra
      my="10px"
    >

    <c-flex align-items="center">
      <c-text
        flex="1"
        font-size="2xl"
      >
        Manajemen Notifikasi
      </c-text>
      <c-button
        h="auto"
        py="12px"
        px="28px"
        color="#927102"
        border-radius="24px"
        border-color="transparent"
        bg="secondary.800"
        variant-color="inherit"
        font-size="16px"
        line-height="24px"
        variant="solid"
        as="router-link"
        :to="{ name: 'admin.notifikasi-new' }"
      >
        <c-image
          :src="require('@/assets/icon-plus.svg')"
          alt="icon"
          display="inline-block"
          mr="10px"
        />
        Tambah Notifikasi
      </c-button>
    </c-flex>

    <c-box
      mt="30px"
      w="100%"
      border="1px solid #f2f2f2"
      box-shadow="4px 4px 50px 5px #0000000D"
      rounded="lg"
      overflow="hidden"
    >
      <c-flex
        align-items="center"
        px="20px"
        h="75px"
      >
        <c-text flex="1">
          Daftar Notifikasi
        </c-text>
      </c-flex>
      <table
        v-chakra
        w="100%"
      >
        <thead
          v-chakra
          bg="#008C8137"
          color="primary.400"
          text-align="left"
          h="40px"
          text-transform="uppercase"
          font-weight="700"
          font-size="12px"
        >
          <tr>
            <th
              v-chakra
              pl="20px"
            >
              No
            </th>
            <th>Tanggal</th>
            <th>Dibuat Oleh</th>
            <th>Nama Notifikasi</th>
            <th>Tipe</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody v-if="notifikasiList.length === 0">
          <tr>
            <td colspan="7">
              <c-flex
                py="80px"
                px="20px"
                justify-content="center"
              >
                <c-box>
                  <c-image
                    :src="require('@/assets/empty-nutritionists.svg')"
                    alt="empty"
                  />
                  <c-text
                    color="gray.900"
                    font-family="Roboto"
                    font-size="24px"
                    margin-top="20px"
                  >
                    Notifikasi tidak ditemukan
                  </c-text>
                </c-box>
              </c-flex>
            </td>
          </tr>
        </tbody>

        <tbody
          v-else
          v-chakra
          text-align="left"
          font-size="14px"
          font-weight="400"
        >
          <tr
            v-for="(item, index) in notifikasiList"
            :key="index"
            v-chakra
            h="90px"
            :border="index > 0 ? '1px solid #f2f2f2' : null"
          >
            <td
              v-chakra
              pl="20px"
            >
              {{ +rows * (page - 1) + (index + 1) }}
            </td>
            <td>{{ item.createdAt | formatDate("DD MMM YYYY") }}</td>
            <td>{{ getItemCreator(item) | formatNull }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.type | capitalize }}</td>
            <td v-chakra>
              <c-button
                type="button"
                variant="outline"
                variant-color="primary"
                rounded="full"
                w="74px"
                h="30px"
                as="router-link"
                :to="{
                  name: 'admin.notifikasi-detail',
                  params: { notifikasiId: item.id },
                }"
              >
                <c-text
                  font-size="12px"
                  font-weight="700"
                  line-height="18px"
                >
                  Lihat
                </c-text>
              </c-button>
            </td>
          </tr>
        </tbody>
      </table>
      <c-flex
        h="85px"
        align-items="center"
        px="40px"
      >
        <c-flex
          flex="1"
          align-items="center"
        >
          <c-text
            color="#888"
            font-weight="400"
            font-size="14px"
          >
            Show :
          </c-text>
          <c-input-group>
            <c-select
              v-model="rows"
              v-chakra
              appearance="none"
              d="inline-block"
              rounded="md"
              ml="10px"
            >
              <option value="10">
                10 baris
              </option>
              <option value="30">
                30 baris
              </option>
              <option value="50">
                50 baris
              </option>
            </c-select>
          </c-input-group>
        </c-flex>

        <c-flex justify-content="flex-end">
          <c-flex
            justify-content="center"
            align="center"
          >
            <c-button
              type="button"
              p="0"
              mr="16px"
              w="32px"
              h="32px"
              min-w="32px"
              variant="outline"
              border-radius="30px"
              background-color="transparent"
              :is-disabled="page === 1"
              as="router-link"
              :to="{ query: { ...$route.query, page: page - 1 } }"
            >
              <c-icon
                name="chevron-left"
                size="24px"
              />
            </c-button>

            <c-button
              v-for="(p, index) in pages"
              :key="index"
              v-chakra="{
                ':hover': {
                  bg: 'rgba(0, 140, 129, 0.3)',
                  color: 'primary.400',
                },
              }"
              p="0"
              m="0"
              w="32px"
              h="32px"
              min-w="32px"
              mx="4px"
              border-radius="30px"
              background-color="rgba(0, 140, 129, 0.3)"
              color="primary.400"
              :bg="p === page ? ['rgba(0, 140, 129, 0.3)'] : ['transparent']"
              :is-disabled="isNaN(+p)"
              as="router-link"
              :to="{ name: $route.name, query: { ...$route.query, page: p } }"
            >
              {{ p }}
            </c-button>

            <c-button
              p="0"
              ml="16px"
              w="32px"
              h="32px"
              min-w="32px"
              variant="outline"
              border-radius="30px"
              background-color="transparent"
              :is-disabled="pages.length === page"
              as="router-link"
              :to="{ query: { ...$route.query, page: page + 1 } }"
            >
              <c-icon
                name="chevron-right"
                size="24px"
              />
            </c-button>
          </c-flex>
        </c-flex>
      </c-flex>
    </c-box>
  </c-flex>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumb.vue'
import { calculatePaginations } from '@/utils/calculate-paginations'
import { capitalize } from 'lodash/string'

export default {
  name: 'SUClientList',
  components: { Breadcrumbs },
  filters: {
    capitalize(str) {
      return capitalize(str)
    },
  },
  data() {
    return {
      notifikasiList: [],
      statuses: [
        { label: 'Semua', value: undefined },
        { label: 'Aktif', value: 'active' },
        { label: 'Tidak Aktif', value: 'non_active' },
        { label: 'Pending', value: 'pending' },
      ],
    }
  },
  computed: {
    page: {
      get() {
        return +(this.$route.query.page ?? 1)
      },
      set(v) {
        if (+this.$route.query.page === v) return
        this.$router.replace({
          query: { ...this.$route.query, page: v },
        })
      },
    },
    rows: {
      get() {
        return this.$route.query.rows ?? '10'
      },
      set(v) {
        if (this.$route.query.rows === v) return
        this.$router.replace({
          query: {
            ...this.$router.query,
            rows: v,
          },
        })
      },
    },
    pages() {
      return calculatePaginations({
        perPage: +this.rows,
        totalData: this.totalData,
        currentPage: this.page,
      })
    },
    filters() {
      return {
        page: this.page,
        perPage: this.rows,
      }
    },
  },
  watch: {
    filters: {
      immediate: true,
      async handler(filter) {
        this.notifikasiList = await this.$store.dispatch(
          'admNotifikasi/listNotifikasi',
          filter,
        )
      },
    },
  },
  methods: {
    getAvatarUrl(avatarUrl) {
      return avatarUrl ?? 'https://ik.imagekit.io/dietela/pwa_webp/profile/profile_neutral.webp'
    },
    getItemCreator(item) {
      return item.createdBy?.fullName
    },
  },
}
</script>
